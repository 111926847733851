@import '../../styles/theme.scss';

.header {
   position: relative;
   z-index: 10;
   .navbar-2 {
      background-color: white;
      width: 100%;
      height: 70px;
      padding: 0px 95px 0px 110px;
      border-bottom: 1.5px solid $grey;

      .navbar-collapse {
         height: 80%;

         .navbar-nav {
            height: 50%;
            margin-left: auto;

            .nav-link {
               font-family: 'Raleway';
               font-style: normal;
               font-weight: 700;
               font-size: 16px;
               line-height: 23px;
               padding: 0px 20px;
               margin: 0px 5px;
               height: 100%;
               display: flex;
               align-items: center;
               // border-bottom: 5px solid $white;
            }

            .nav-link:hover {
               border-bottom: 2px solid black;
            }
         }
      }
   }

   .scroll {
      // position: fixed;
      width: 100%;
      transition: top 0.2s;
   }

   .scroll.visible {
      z-index: 1;
   }

   .scroll.hidden {
      top: -100px;
      z-index: 1;
   }
   @media only screen and (max-width: 600px) {
      .mob{
         background-color: white;
         display: flex;
         gap: 5%;
         height: 250px !important;
         width: 500vw !important;
         display: flex;
      }
      .buttonss{
         display: none !important;
      }
      .navbar{
         justify-content: space-between;
         display: flex;
         width: 100vw !important;
      }
   }
}