.search {
  display: flex;
  align-items: center;
  background-color: #edeeef;
  /* border: 0.1px solid lightgray; */
  padding: 3px;
  border-radius: 8px;
  width: 250px;
  /* margin-right: 30px ; */
}
.search .icon {
  font-size: 20px;
  color: #9fa2ab;
  padding-left: 20px;
  padding-right: 20px;
}
.search input {
  font-size: 20px;
  color: black;
  width: 250px;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: transparent;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  align-items: center;
}
.search input::placeholder {
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
  color: #9fa2ab;
}
.items {
  display: flex;
  align-items: center;
}
.items .item {
  align-items: center;
  display: flex;
  margin-right: 20px;
  position: reletive;
}
.items .icon {
  font-size: 30px;
}
.items .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.items .counter {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  top: 10px;
}
