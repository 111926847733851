@import "../../styles/colors.scss";

.contact {
  margin: auto;
  background-color: rgb(0, 0, 0, 0.1);
  width: 500px;
  padding: 20px;
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.day {
  display: flex;

  justify-content: space-between;
}
.p {
  font-weight: bold;
}
.add {
  color: rgb(0, 97, 158);
  text-align: center;
}
.add1 {
  font-weight: bold;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .contact {
    width: 95%;
  }
}