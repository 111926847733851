.text-84 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 84px;
   line-height: 104px;
   margin: 0px;
   color: $base;
}

.text-72 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 72px;
   line-height: 89px;
   margin: 0px;
   color: $base;
}

.text-60 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 60px;
   line-height: 75px;
   margin: 0px;
   color: $base;
}

.text-48 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 48px;
   line-height: 60px;
   margin: 0px;
   color: $base;
}

.text-42 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 42px;
   line-height: 49px;
   margin: 0px;
   color: $base;
}

.text-36 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 36px;
   line-height: 45px;
   margin: 0px;
   color: $base;
}

.text-32 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 32px;
   line-height: 38px;
   margin: 0px;
   color: $base;
}

.text-30 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 30px;
   line-height: 39px;
   margin: 0px;
   color: $base;
}

.text-24 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 24px;
   line-height: 31px;
   margin: 0px;
   color: $base;
}

.text-22 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 22px;
   margin: 0px;
   color: $base;
}

.text-20 {
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 26px;
   margin: 0px;
   color: $base;
}

.text-18 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 18px;
   line-height: 23px;
   margin: 0px;
   color: $base;
}

.text-16 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 16px;
   line-height: 21px;
   margin: 0px;
   color: $base;
}

.text-14 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 14px;
   margin: 0px;
   color: $base;
}

.text-12 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 12px;
   line-height: 14px;
}

.text-10 {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 10px;
   line-height: 13px;
   margin: 0px;
   color: $base;
}