$primary: rgba(67, 180, 13, 1);
$secondary: rgba(20, 109, 169, 1);
$tertiary: rgba(74, 58, 143, 1);
$base: rgba(43, 41, 38, 1);
$white: rgba(253, 252, 252, 1);
$white80: rgba(240, 249, 247, 1);
$grey: rgba(140, 140, 140, 1);
$inputBg: #FAFAFC;

$gradient-1: linear-gradient(124.51deg, #79C853 15.13%, #FFFFFF 109.42%);
$gradient-2: linear-gradient(126.81deg, #79C853 17.04%, #4ECC00 45.46%, #53B7ED 67.42%, #56A4D8 86.03%);
$gradient-3: linear-gradient(126.51deg, #408ECC 27.94%, #FFFFFF 173.44%);